import React, { useState, useEffect } from 'react'
import { Form, Input, Button, message } from 'antd'
import './index.css'
import axios from 'axios'
import { strapiURL } from '../../config'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { paramsReturnHandler } from '../../data'
import { navigate } from 'gatsby'
const ResetPasswordForm = ({ location }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  useEffect(() => {
    if (location?.search) {
      let search = paramsReturnHandler(location?.search)
      if (!search.code) {
        navigate('/')
        return
      }
      setCode(search.code)
    }
  }, [location])

  const sendEmail = values => {
    setLoading(true)
    axios
      .post(strapiURL + '/auth/reset-password', {
        code,
        password: values?.password,
        passwordConfirmation: values?.password,
      })
      .then(res => {
        form.resetFields()
        message.success('Password updated')
        setLoading(false)
        navigate('/')
      })
      .catch(err => {
        console.log('error ---', err)
        // form.resetFields()
        message.error('Unable to update password')
        setLoading(false)
      })
  }

  return (
    <div className="reset-form-container">
      <h1 className="title">Reset Password</h1>
      <p className="description">
        Please enter a password that will be used for the log in.
      </p>
      <Form
        layout="vertical"
        onFinish={sendEmail}
        name="resetPassword"
        form={form}
      >
        <Form.Item
          className="theme-form-item"
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please enter password',
            },
            { min: 8, message: 'Password must be minimum 8 characters.' },
            {
              pattern: RegExp(
                /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])).{8,16}$/
              ),
              message:
                'Password must include at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)',
            },
          ]}
        >
          <Input.Password
            placeholder="Input password"
            iconRender={visible =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <div className="button_show">
          <Button htmlType="submit" className="profileSubmitButton">
            {loading ? 'Loading...' : 'Submit'}
          </Button>
        </div>
      </Form>
    </div>
  )
}
export default ResetPasswordForm
