import React from 'react'
import ResetPasswordForm from '../components/reset-password'
import Wrapper from '../components/wrapper'
const ResetPassword = ({ location }) => {
  return (
    <Wrapper location={location}>
      <ResetPasswordForm location={location} />
    </Wrapper>
  )
}
export default ResetPassword
